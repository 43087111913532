.pdf-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust as needed */
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
}
